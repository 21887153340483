import { useState } from 'react';
import Range from 'rc-slider';
import 'rc-slider/assets/index.css';
import { styled } from '../../stitches.config';

type PropType = {
  onFilterChange: (queryString: string) => void;
  minPrice: number;
  maxPrice: number;
  defaultMaxPrice: number;
  minPriceFormatted?: string;
  maxPriceFormatted?: string;
};

function PriceRangeSelector({
  onFilterChange,
  minPrice: initialMinPrice,
  maxPrice: initialMaxPrice,
  defaultMaxPrice,
  minPriceFormatted,
  maxPriceFormatted,
}: PropType) {
  const [minPrice, setMinPrice] = useState<number>(initialMinPrice);
  const [maxPrice, setMaxPrice] = useState<number>(initialMaxPrice);

  const onPriceChange = (values: any) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
  };

  const onPriceChangeEnd = (values: any) => {
    const filterCriteria = [`price_range=${values[0]}-${values[1]}`];
    const ignoredParams = ['price_range'];
    const unChangedParams = (window.location.search.substr(1) || '')
      .split('&')
      .filter((param) => {
        const [id] = param.split('=');

        return param.length > 0 && !ignoredParams.includes(id);
      });

    const q = [...unChangedParams, ...filterCriteria].join('&');

    setMinPrice(values[0]);
    setMaxPrice(values[1]);
    onFilterChange(q);
  };

  return (
    <Wrapper>
      <PriceFilterContainer>
        <RangeContainer>
          <ValuesWrapper>
            <span>{minPriceFormatted}</span>
            <span>{maxPriceFormatted}</span>
          </ValuesWrapper>
          <>
            <RangeWrapper>
              <Range
                key={'test'}
                allowCross={false}
                range={true}
                handleStyle={{
                  borderColor: '#494948',
                  borderWidth: 5,
                  height: 18,
                  width: 18,
                  opacity: 1,
                  backgroundColor: '#F7F6F5',
                  boxShadow: 'unset',
                }}
                dotStyle={{ backgroundColor: '#484848' }}
                trackStyle={{
                  backgroundColor: '#484848',
                  height: '2px',
                  marginTop: '2px',
                }}
                railStyle={{
                  background: '#A3A3A3',
                  height: '2px',
                  marginTop: '2px',
                }}
                max={defaultMaxPrice}
                onAfterChange={(e) => onPriceChangeEnd(e)}
                onChange={(e) => onPriceChange(e)}
                value={[minPrice, maxPrice]}
              />
            </RangeWrapper>
          </>
        </RangeContainer>
      </PriceFilterContainer>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  w: '100%',
  px: 1,
});

const ValuesWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const RangeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
});

const RangeWrapper = styled('div', {
  mt: 3,
  pl: 1.5,
  pr: 1.5,
});

const PriceFilterContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  w: '100%',
  justifyContent: 'space-between',
});

export default PriceRangeSelector;
