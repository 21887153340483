import GridIcon from '../../Atoms/Icons/GridIcon';
import ListViewIcon from '../../Atoms/Icons/ListViewIcon';
import { styled } from '../../stitches.config';

type PropTypes = {
  onClick: (val: boolean) => void;
  isListView: boolean;
};

function GridListToggle({ onClick, isListView }: PropTypes) {
  return (
    <ButtonsWrapper>
      <ButtonsContainer>
        <Button
          onClick={() => {
            onClick(false);
          }}
        >
          <GridIcon isSelected={!isListView} />
        </Button>
        <Button
          onClick={() => {
            onClick(true);
          }}
        >
          <ListViewIcon isSelected={isListView} />
        </Button>
      </ButtonsContainer>
    </ButtonsWrapper>
  );
}

const ButtonsWrapper = styled('div', {
  ml: 8,
});

const ButtonsContainer = styled('div', {
  display: 'flex',
  w: '100%',
  justifyContent: 'flex-end',
});

const Button = styled('button', {
  p: 2,
  ml: 2,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$white',
});

export default GridListToggle;
