import { useRef, useState } from 'react';
import ChevronIcon from '../../Atoms/Icons/ChevronIcon';
import SortingIcon from '../../Atoms/Icons/SortingIcon';
import SearchSorter from '../../Search/Models/SearchSorter.interface';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import { styled } from '../../stitches.config';

type PropTypes = {
  selectedSorter: SearchSorter;
  sorters?: SearchSorter[];
  onSorterClick?: (
    by: string,
    direction: number,
    closeDropdown: () => void
  ) => void;
};

function ProductSorter({ selectedSorter, sorters, onSorterClick }: PropTypes) {
  const sorterRef = useRef(null);
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
  const onSortButtonClick = () => {
    setSortDropdownOpen(!sortDropdownOpen);
  };

  useOutsideClick(sorterRef, () => {
    sortDropdownOpen && setSortDropdownOpen(false);
  });

  return (
    <SortersButtonWrapper ref={sorterRef}>
      <SorterButton onClick={() => onSortButtonClick()}>
        <SelectedSortedSpan>{selectedSorter?.text}</SelectedSortedSpan>
        <ChevronIcon
          size={'xs'}
          rotateDown
          rotateUp={sortDropdownOpen}
          transition
        />
      </SorterButton>
      {sortDropdownOpen && (
        <SortersDropdownContainer>
          {sorters?.map(
            (sorter: SearchSorter) =>
              !sorter.selected &&
              onSorterClick && (
                <SorterItem
                  key={sorter.value}
                  onClick={() =>
                    onSorterClick(sorter.sortBy, sorter.sortDirection, () =>
                      setSortDropdownOpen(false)
                    )
                  }
                >
                  {sorter.text}
                </SorterItem>
              )
          )}
        </SortersDropdownContainer>
      )}
    </SortersButtonWrapper>
  );
}

export default ProductSorter;

const SortersButtonWrapper = styled('div', {
  background: 'transparent',
  display: 'flex',
  position: 'relative',
  px: '16px',
});

const SelectedSortedSpan = styled('div', {
  fontWeight: '$fw400',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
  paddingRight: '12px',
  textAlign: 'right',
  textTransform: 'uppercase',
});

const SorterItem = styled('li', {
  px: '16px',
  py: '8px',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const SorterButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  w: '100%',
});

const SortersDropdownContainer = styled('ul', {
  background: '$white',
  position: 'absolute',
  t: 8,
  right: 0,
  w: '159px',
  zIndex: 1,
  listStyle: 'none',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
  textAlign: 'right',
  paddingTop: '10px',
  paddingBottom: '24px',
  border: '1px solid $grey100',
});
