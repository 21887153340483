import { styled } from '../../stitches.config';
import { FilterIcon } from '../../Atoms/Icons';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import SearchSorter from '../../Search/Models/SearchSorter.interface';
import ProductSorter from '../ProductSorter/ProductSorter';
import { useReducer } from 'react';
import filterMenuReducer from '../../Organisms/Header/Reducers/filterMenuReducer';
import FilterMenu from '../../Organisms/FilterMenu/FilterMenu';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import KexFacetGroupFilter from '../../Search/Models/KexFacetGroupFilter.interface';

type PropTypes = {
  selectedSorter?: SearchSorter;
  sorters?: SearchSorter[];
  filters?: KexFacetGroupFilter[];
  facetFilters?: KexFacetGroupFilter[];
  setFacetFilters: (value: KexFacetGroupFilter[]) => void;
  currency?: string;
  priceValue?: number[];
  onFilterChange: (queryString: string) => void;
  onSorterClick?: (
    by: string,
    direction: number,
    closeDropdown: () => void
  ) => void;
  filterValue?: string;
  clearFilter: () => void;
  showFilter?: Boolean;
};

const FilterBar = ({
  selectedSorter,
  sorters,
  onSorterClick,
  onFilterChange,
  filters,
  facetFilters,
  setFacetFilters,
  currency,
  filterValue,
  clearFilter,
  showFilter,
  ...rest
}: PropTypes) => {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinHuge);

  const [{ filterMenuIsOpen, mountFilterMenu }, filterMenuDispatch] =
    useReducer(filterMenuReducer, {
      filterMenuIsOpen: false,
      mountFilterMenu: true,
    });

  const { 'common/filter': filterLabel } = useTranslationData();

  return (
    <>
      <BarContainer {...rest}>
        {showFilter !== false && isDesktop ? (
          <Container>
            <FilterMenu
              isOpen={filterMenuIsOpen}
              closeFilterMenu={() => null}
              onFilterChange={onFilterChange}
              filters={filters}
              facetFilters={facetFilters}
              setFacetFilters={setFacetFilters}
              clearFilter={clearFilter}
            />
          </Container>
        ) : (
          <>
            <Container>
              {showFilter !== false && (
                <IconContainer
                  onClick={() => {
                    filterMenuDispatch(
                      filterMenuIsOpen ? 'closeFilterMenu' : 'mountAndOpen'
                    );
                  }}
                >
                  <FilterIcon size="s" color="primary" />
                  <IconLabel>{filterLabel}</IconLabel>
                </IconContainer>
              )}
              {selectedSorter && (
                <IconContainer>
                  <ProductSorter
                    selectedSorter={selectedSorter}
                    sorters={sorters}
                    onSorterClick={onSorterClick}
                  />
                </IconContainer>
              )}
              {showFilter !== false && mountFilterMenu && (
                <FilterMenu
                  isOpen={filterMenuIsOpen}
                  closeFilterMenu={() => filterMenuDispatch('closeFilterMenu')}
                  onFilterChange={onFilterChange}
                  filters={filters}
                  facetFilters={facetFilters}
                  setFacetFilters={setFacetFilters}
                  filterValue={filterValue}
                  clearFilter={clearFilter}
                />
              )}
            </Container>
          </>
        )}
      </BarContainer>
    </>
  );
};

export default FilterBar;

const BarContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  py: '24px',
  width: '100%',
  '@mediaMinHuge': {
    py: 2,
    mb: 12,
  },
});

const Container = styled('div', {
  display: 'flex',
  w: '100%',
  maxW: '100%',
  justifyContent: 'space-between',
  '@mediaMinHuge': {
    justifyContent: 'left',
  },
});

const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fs: 7,
  justifyContent: 'center',
  cursor: 'pointer',
});

const IconLabel = styled('span', {
  fontWeight: '$fw700',
  lineHeight: '$lh2',
  ls: '$ls07',
  color: '$primary',
  ml: 2,
});
