type FilterMenuStateType = {
  filterMenuIsOpen: boolean;
  mountFilterMenu: boolean;
};

type FilterMenuActionType =
  | 'mountAndOpen'
  | 'closeFilterMenu'
  | 'unMountFilterMenu';

const filterMenuReducer = (
  state: FilterMenuStateType,
  action: FilterMenuActionType
) => {
  switch (action) {
    case 'mountAndOpen': {
      return {
        ...state,
        filterMenuIsOpen: true,
        mountFilterMenu: true,
      };
    }
    case 'closeFilterMenu': {
      return {
        ...state,
        filterMenuIsOpen: false,
      };
    }
    case 'unMountFilterMenu': {
      return {
        ...state,
        mountFilterMenu: false,
        filterMenuIsOpen: false,
      };
    }
  }
};

export default filterMenuReducer;
