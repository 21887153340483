import React from 'react';
import { styled } from '../../stitches.config';
import KexContentLinkModel from '../../Search/Models/KexContentLinkModel.interface';
import {
  useMount,
  useMeasure,
  usePrevious,
  useShallowCompareEffect,
  useUpdateEffect,
} from 'react-use';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import KexLink from '../../Kex/KexLink';

type PropTypes = {
  categories: KexContentLinkModel[];
  noSidePadding?: boolean;
};

type CollapseDirection = 'start' | 'end';

type OverflowDirection = 'none' | 'grow' | 'shrink';

interface OverflowListProps<T> {
  items: T[];
  itemRenderer: (item: T, index: number) => React.ReactNode;
  overflowRenderer: (items: T[]) => React.ReactNode;
  minVisibleItems?: number;
  collapseFrom?: CollapseDirection;
  className?: string;
  tagName?: keyof JSX.IntrinsicElements;
  alwaysRenderOverflow?: boolean;
}

interface OverflowListState<T> {
  visible: T[];
  overflow: T[];
  lastOverflowCount: number;
  overflowDirection: OverflowDirection;
}

interface OverflowListProps<T> {
  items: T[];
  itemRenderer: (item: T, index: number) => React.ReactNode;
  overflowRenderer: (items: T[]) => React.ReactNode;
  minVisibleItems?: number;
  collapseFrom?: CollapseDirection;
  className?: string;
  tagName?: keyof JSX.IntrinsicElements;
  alwaysRenderOverflow?: boolean;
  noSidePadding?: boolean;
}

interface OverflowListState<T> {
  visible: T[];
  overflow: T[];
  lastOverflowCount: number;
  overflowDirection: OverflowDirection;
}

function OverflowList<T>(props: OverflowListProps<T>) {
  let {
    items,
    collapseFrom = 'end',
    minVisibleItems = 0,
    alwaysRenderOverflow = false,
    overflowRenderer,
    itemRenderer,
    noSidePadding,
  } = props;
  const [state, setState] = React.useState<OverflowListState<T>>({
    visible: items,
    overflow: [],
    lastOverflowCount: 0,
    overflowDirection: 'none',
  });

  const containerRef = React.useRef<HTMLDivElement>(null);
  const spacer = React.useRef<HTMLDivElement>(null);

  useShallowCompareEffect(() => {
    repartition(false);
  }, [state]);

  useMount(() => {
    repartition(false);
  });

  useUpdateEffect(() => {
    setState(() => ({
      overflowDirection: 'none',
      lastOverflowCount: 0,
      overflow: [],
      visible: items,
    }));
  }, [items]);

  const maybeOverflow =
    state.overflow.length === 0 && !alwaysRenderOverflow
      ? null
      : overflowRenderer(state.overflow);

  const repartition = (growing: boolean) => {
    if (!spacer.current) {
      return;
    }

    if (growing) {
      setState((state) => ({
        overflowDirection: 'grow',
        lastOverflowCount:
          state.overflowDirection === 'none'
            ? state.overflow.length
            : state.lastOverflowCount,
        overflow: [],
        visible: props.items,
      }));
    } else if (spacer.current.getBoundingClientRect().width < 0.9) {
      setState((state) => {
        if (state.visible.length <= minVisibleItems!) {
          return state;
        }
        const collapseFromStart = collapseFrom === 'start';
        const visible = state.visible.slice();
        const next = collapseFromStart ? visible.shift() : visible.pop();

        if (!next) {
          return state;
        }
        const overflow = collapseFromStart
          ? [...state.overflow, next]
          : [next, ...state.overflow];
        return {
          ...state,
          direction:
            state.overflowDirection === 'none'
              ? 'shrink'
              : state.overflowDirection,
          overflow,
          visible,
        };
      });
    } else {
      setState((prevState) => {
        return { ...prevState, overflowDirection: 'none' };
      });
    }
  };

  const [ref, { width }] = useMeasure<any>();
  const previousWidth = usePrevious(width);
  React.useEffect(() => {
    if (!previousWidth) return;

    repartition(width > previousWidth);
  }, [width, previousWidth]);

  const showAll = () => {
    alwaysRenderOverflow = true;
    repartition(true);

    if (containerRef && containerRef.current)
      containerRef.current.style.flexWrap = 'wrap';
  };

  return (
    <>
      <BarContainer>
        <Container ref={containerRef} noSidePadding={noSidePadding}>
          {state.visible.map(itemRenderer)}
          <div style={{ flexShrink: 1, width: 1 }} ref={spacer} />
        </Container>
        {collapseFrom === 'end' ? (
          <button onClick={showAll}>{maybeOverflow}</button>
        ) : null}
      </BarContainer>
    </>
  );
}

const ItemRenderer = (category: KexContentLinkModel) => {
  return (
    <CategoryContainer key={category?.url} href={category?.url}>
      <IconContainer key={category?.url}>
        <img src={category?.image?.src} alt={category?.image?.alt} />
      </IconContainer>
      <IconLabel>{category?.name}</IconLabel>
    </CategoryContainer>
  );
};

const OverflowRenderer = (items: KexContentLinkModel[]) => {
  const { 'filter/showAllFilters': showAllFiltersLabel } = useTranslationData();

  return (
    <ShowMore>
      {showAllFiltersLabel} (+{items.length})
    </ShowMore>
  );
};

const CategoryBar = ({ categories, noSidePadding }: PropTypes) => {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  return isDesktop ? (
    <OverflowList
      collapseFrom="end"
      minVisibleItems={0}
      items={categories}
      itemRenderer={ItemRenderer}
      overflowRenderer={OverflowRenderer}
      noSidePadding={noSidePadding}
    />
  ) : (
    <BarContainer>
      <Container noSidePadding={noSidePadding}>
        {categories.map((category: KexContentLinkModel) => (
          <CategoryContainer key={category?.url} href={category?.url}>
            <IconContainer key={category?.url}>
              <img src={category?.image?.src} alt={category?.name} />
            </IconContainer>
            <IconLabel>{category?.name}</IconLabel>
          </CategoryContainer>
        ))}
      </Container>
    </BarContainer>
  );
};

export default CategoryBar;

const BarContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  mb: '32px',
  '@mediaMinLarge': {
    overflow: 'hidden',
    maxW: '$contentMaxWidth',
    mx: 'auto',
  },
});

const ShowMore = styled('div', {
  mt: '52px',
  fontSize: '12px',
  fontWeight: '$fw400',
  lineHeight: '20px',
  letterSpacing: '1px',
});

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  w: '100%',
  maxW: '100%',
  g: '32px',
  flexWrap: 'nowrap',
  minWidth: 0,
  px: 4,
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  overflow: 'auto',
  '@mediaMinLarge': {
    overflow: 'hidden',
    flexWrap: 'wrap',
    px: 5,
  },
  variants: {
    noSidePadding: {
      true: {
        px: 0,
      },
    },
  },
});

const CategoryContainer = styled(KexLink, {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  textDecoration: 'none',
  maxW: '68px',
  '@mediaMinLarge': {
    maxW: '105px',
  },
});

const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  wh: '68px',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  background: '$white',
  br: '100%',
  '& img': {
    objectFit: 'contain',
    position: 'absolute',
    top: '100%',
    left: '100%',
    height: '100%',
    width: '100%',
    transform: 'translate(-100%,-100%)',
  },
  '@mediaMinLarge': {
    width: '105px',
    height: '105px',
    maxW: '105px',
  },
});

const IconLabel = styled('span', {
  fontWeight: '$fw400',
  fontSize: '10px',
  lineHeight: '20px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  marginTop: '16px',
  textAlign: 'center',
  '@mediaMinLarge': {
    fontSize: '12px',
  },
});
