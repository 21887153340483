import { styled } from '../../stitches.config';

type PropType = {
  loaded: number;
};

function ProgressBar({ loaded }: PropType) {
  return (
    <Bar>
      <Loaded css={{ width: loaded ? `${loaded}%` : '0' }} />
    </Bar>
  );
}

const Bar = styled('div', {
  position: 'relative',
  display: 'flex',
  w: 65,
  h: 2,
  br: 8,
  backgroundColor: '$grey500',
  $mediaMinSmall: {
    w: 90,
  },
});

const Loaded = styled('div', {
  backgroundColor: '$primary',
  top: 0,
  left: 0,
  w: 0,
  br: 8,
  transition: '$1000 cubic-bezier(0.25, 0.46, 0.45, 0.94)',
});

export default ProgressBar;
