import { styled } from '../../stitches.config';
import Button from '../../Atoms/Buttons/Button';
import CirclePlusIcon from '../../Atoms/Icons/CirclePlusIcon';
import ProgressBar from '../../Atoms/Loaders/ProgressBar';

type PropType = {
  loaded: number;
  counterText: string;
  loadMore: string;
  disabled?: boolean;
  onClick?: (x?: () => void) => void;
};

const LoadMoreContainer = ({
  loaded,
  counterText,
  loadMore,
  disabled = false,
  onClick,
}: PropType) => {
  return (
    <LoadContainer>
      <ProgressBar loaded={loaded} />
      <Counter>{counterText}</Counter>
      <StyledButton
        disabled={disabled}
        type={'secondary'}
        onClick={() => {
          onClick && onClick();
        }}
        size={'m'}
      >
        {loadMore}
      </StyledButton>
    </LoadContainer>
  );
};

export default LoadMoreContainer;

const LoadContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mb: 8,
  mx: 'auto',
  '@mediaMinLarge': {
    mb: 16,
  },
});

const Counter = styled('span', {
  fs: 7,
  fontWeight: '$fw700',
  color: '$primary',
  ls: '$ls08',
  lineHeight: '$lh2',
  mt: 4,
  mb: 8,
});

const StyledButton = styled(Button, {});
