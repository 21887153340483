import { useEffect, useRef, useState } from 'react';
import { CloseIcon } from '../../Atoms/Icons';
import PriceRangeSelector from '../../Pages/PriceRangeSelector/PriceRangeSelector';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import Accordion from '../../Molecules/Accordion/Accordion';
import KexFacetGroupFilter from '../../Search/Models/KexFacetGroupFilter.interface';
import KexFacetFilter from '../../Search/Models/KexFacetFilter.interface';
import Checkbox from '../../Atoms/Checkbox/Checkbox';
import { GroupType } from '../../Search/Models/GroupType.interface';
import { FilterType } from '../../Search/Models/FilterType.interface';

export type PropType = {
  isOpen: boolean;
  closeFilterMenu: () => void;
  onFilterChange: (queryString: string) => void;
  filters?: KexFacetGroupFilter[];
  facetFilters?: KexFacetGroupFilter[];
  setFacetFilters: (value: KexFacetGroupFilter[]) => void;
  filterValue?: string;
  clearFilter: () => void;
};

function FilterMenu({
  isOpen,
  closeFilterMenu,
  onFilterChange,
  filters,
  facetFilters,
  setFacetFilters,
  filterValue,
  clearFilter,
}: PropType) {
  const { 'categoryPage/clearFilter': clearFilterText } = useTranslationData();
  const menuRef = useRef<HTMLDivElement>(null);
  const [renderMenu, setRenderMenu] = useState<boolean>(false);
  const isDesktop = useMedia(mediaQueryTypes.mediaMinHuge);

  useOutsideClick(menuRef, () => {
    isOpen && closeFilterMenu();
  });

  const onSearchFacetChange = (
    filter: KexFacetGroupFilter,
    option: KexFacetFilter
  ) => {
    if (filter) {
      const allFilters = facetFilters;
      const newFilters = updateFilterOption(allFilters || [], filter, option);
      setFacetFilters(newFilters);

      return submit(newFilters);
    }
  };

  const submit = (facetFilters: KexFacetGroupFilter[]) => {
    const filterCriteria = toFilterCriteria(facetFilters);
    const filterIds = facetFilters.map((filter) => filter.id);
    const ignoredParams = ['page'];
    const unChangedParams = (window.location.search.substr(1) || '')
      .split('&')
      .filter((param) => {
        const [id] = param.split('=');
        return (
          param.length > 0 &&
          !filterIds.includes(id) &&
          !ignoredParams.includes(id)
        );
      });

    const q = [...unChangedParams, ...filterCriteria].join('&');

    onFilterChange(q);
  };

  const toFilterCriteria = (groups: KexFacetGroupFilter[]) =>
    groups
      .map((group) =>
        group.selectedOptions
          .filter((val) => val && val.length > 0)
          .map(
            (val) =>
              `${encodeURIComponent(group.id)}=${encodeURIComponent(val)}`
          )
      )
      .reduce((flat, current) => [...flat, ...current], []);

  const updateFilterOption = (
    allFilters: KexFacetGroupFilter[],
    filter: KexFacetGroupFilter,
    option: KexFacetFilter
  ) => {
    if (!filter) return allFilters;

    const filterIndex = allFilters.findIndex((f) => f.id === filter.id);
    const newFilter = option
      ? toggleFilterValue(filter, option)
      : { ...filter, selectedOptions: [] };
    const newFilters = [
      ...allFilters.slice(0, filterIndex),
      newFilter,
      ...allFilters.slice(filterIndex + 1),
    ];

    return newFilters;
  };

  const toggleFilterValue = (
    filter: KexFacetGroupFilter,
    option: KexFacetFilter
  ) => {
    const { singleSelect, selectedOptions } = filter;
    const optionIndex = selectedOptions.indexOf(option.id);
    const newSelectedOptions =
      optionIndex < 0
        ? // not yet selected, select it
          singleSelect
          ? [option.id]
          : [...selectedOptions, option.id]
        : // selected, deSelect  it
          [
            ...selectedOptions.slice(0, optionIndex),
            ...selectedOptions.slice(optionIndex + 1),
          ];

    return { ...filter, selectedOptions: newSelectedOptions };
  };

  useEffect(() => {
    if (isOpen) {
      setRenderMenu(true);
    }
  }, [isOpen]);

  return (
    <>
      {isDesktop ? (
        <FilterMenuContentContainer>
          {filters &&
            filters?.map((item: KexFacetGroupFilter) =>
              item.type === GroupType.Accordion ? (
                <Accordion
                  type="filter"
                  title={item?.label}
                  defaultActive={true}
                >
                  <FilterSection key={item?.id}>
                    {item?.options &&
                      item?.options?.map((filter: KexFacetFilter) => (
                        <div key={filter?.id}>
                          {filter.type === FilterType.Checkbox ? (
                            <Checkbox
                              name={filter?.id}
                              key={filter?.id}
                              checked={
                                item?.selectedOptions.indexOf(filter.id) >= 0 &&
                                true
                              }
                              value={filter?.id}
                              filterType={'text'}
                              onChange={(value, checked, filterType) =>
                                onSearchFacetChange(item, filter)
                              }
                            >
                              <FilterText>
                                <span>{filter.label}</span>
                              </FilterText>
                            </Checkbox>
                          ) : (
                            <PriceRangeSelector
                              onFilterChange={onFilterChange}
                              minPrice={filter.minPrice ?? 0}
                              maxPrice={filter.maxPrice ?? 0}
                              defaultMaxPrice={filter.defaultMaxPrice ?? 0}
                              minPriceFormatted={filter.minPriceFormatted}
                              maxPriceFormatted={filter.maxPriceFormatted}
                            />
                          )}
                        </div>
                      ))}
                  </FilterSection>
                </Accordion>
              ) : (
                <SingleFilterSection>
                  {item?.options &&
                    item?.options?.map((filter: KexFacetFilter) => (
                      <Checkbox
                        name={item?.id}
                        key={item?.id}
                        checked={filter.selected}
                        value={filter?.id}
                        filterType={'text'}
                        onChange={(value, checked, filterType) =>
                          onSearchFacetChange(item, filter)
                        }
                      >
                        <FilterText>
                          <span>{filter.label}</span>
                        </FilterText>
                      </Checkbox>
                    ))}
                </SingleFilterSection>
              )
            )}
        </FilterMenuContentContainer>
      ) : (
        <>
          <Root
            isOpen={isOpen}
            ref={menuRef}
            onTransitionEnd={() => {
              !isOpen && setRenderMenu(false);
            }}
          >
            {renderMenu && (
              <FilterMenuContentContainer>
                <TopSection>
                  <IconWrapper onClick={closeFilterMenu}>
                    <CloseIcon size="xs" />
                  </IconWrapper>
                </TopSection>
                <Filters>
                  {filterValue && (
                    <ClearFilterText onClick={clearFilter}>
                      {clearFilterText}
                    </ClearFilterText>
                  )}
                  {filters &&
                    filters?.map((item: KexFacetGroupFilter) =>
                      item.type === GroupType.Accordion ? (
                        <Accordion
                          type="filter"
                          title={item?.label}
                          defaultActive={true}
                        >
                          <FilterSection key={item?.id}>
                            {item?.options &&
                              item?.options?.map((filter: KexFacetFilter) => (
                                <div key={filter?.id}>
                                  {filter.type === FilterType.Checkbox ? (
                                    <Checkbox
                                      name={filter?.id}
                                      key={filter?.id}
                                      checked={
                                        item?.selectedOptions.indexOf(
                                          filter.id
                                        ) >= 0 && true
                                      }
                                      value={filter?.id}
                                      filterType={'text'}
                                      onChange={(value, checked, filterType) =>
                                        onSearchFacetChange(item, filter)
                                      }
                                    >
                                      <FilterText>
                                        <span>{filter.label}</span>
                                      </FilterText>
                                    </Checkbox>
                                  ) : (
                                    <PriceRangeSelector
                                      onFilterChange={onFilterChange}
                                      minPrice={filter.minPrice ?? 0}
                                      maxPrice={filter.maxPrice ?? 0}
                                      defaultMaxPrice={
                                        filter.defaultMaxPrice ?? 0
                                      }
                                      minPriceFormatted={
                                        filter.minPriceFormatted
                                      }
                                      maxPriceFormatted={
                                        filter.maxPriceFormatted
                                      }
                                    />
                                  )}
                                </div>
                              ))}
                          </FilterSection>
                        </Accordion>
                      ) : (
                        <SingleFilterSection>
                          {item?.options &&
                            item?.options?.map((filter: KexFacetFilter) => (
                              <Checkbox
                                name={filter?.id}
                                key={filter?.id}
                                checked={filter.selected}
                                value={filter?.id}
                                filterType={'text'}
                                onChange={(value, checked, filterType) =>
                                  onSearchFacetChange(item, filter)
                                }
                              >
                                <FilterText>
                                  <span>{filter.label}</span>
                                </FilterText>
                              </Checkbox>
                            ))}
                        </SingleFilterSection>
                      )
                    )}
                </Filters>
              </FilterMenuContentContainer>
            )}
          </Root>
        </>
      )}
    </>
  );
}

export default FilterMenu;

const Root = styled('div', {
  transition: 'all $300',
  width: '100%',
  maxWidth: '450px',
  l: 0,
  t: 0,
  h: '100%',
  overflow: 'auto',
  zIndex: '$Flyout',
  backgroundColor: '$backgroundPrimary',
  boxShadow:
    '0 2px 32px 2px rgba(59, 72, 80, 0.05), 0 0 4px 0 rgba(72, 72, 72, 0.15)',
  position: 'absolute',
  variants: {
    isOpen: {
      true: {
        transform: 'translateX(0)',
        h: '100%',
      },
      false: {
        transform: 'translateX(-200%)',
      },
    },
  },
});

const FilterMenuContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const FilterText = styled('div', {
  fontWeight: '$fw400',
  fontSize: '14px',
  lineHeight: '24px',
});

const Filters = styled('div', {
  overflow: 'auto',
  p: 4,
});

const TopSection = styled('div', {
  display: 'flex',
  alignItems: 'center',
  p: 4,
  borderBottom: '1px solid $grey100',
});

const SingleFilterSection = styled('div', {
  '@mediaMinLarge': {
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingTop: '0',
    px: 0,
  },
});

const FilterSection = styled('div', {
  p: 4,
  '@mediaMinLarge': {
    paddingTop: '28px',
    paddingBottom: '56px',
    px: 0,
  },
});

const IconWrapper = styled('div', {
  cursor: 'pointer',
});

const ClearFilterText = styled(`button`, {
  fontSize: '12px',
  fontWeight: '$fw400',
  lineHeight: '20px',
  letterSpacing: '1px',
  marginBottom: '32px',
});
